import React, { useState } from "react"
import { usePortfolio } from "../../context/PortfolioContext"
import Layout from "../../components/UI/layout"
import PortfolioTable from "../../components/UI/Tables/PortfolioTable"
import { ResponsivePie } from "@nivo/pie"
import { ResponsiveTreeMap } from "@nivo/treemap"
import SectionHeader from "../../components/UI/elements/SectionHeader"

const Allocation = () => {
  const { holdings, categories, ecosystems, mv } = usePortfolio()
  const [showCategories, setShowCategories] = useState(true)
  const [detailCategory, setDetailCategory] = useState(null)

  const cats = categories
    .map(cat => {
      return {
        name: cat.name,
        allocation:
          holdings
            .filter(h => h.asset.categoryName === cat.name)
            .reduce((acc, item) => acc + item.units * item.price.price, 0) / mv,
        color: cat.color,
      }
    })
    .sort((a, b) => b.allocation - a.allocation)

  const ecos = ecosystems
    .map(eco => {
      return {
        name: eco.name,
        allocation:
          holdings
            .filter(h => h.asset.ecosystemName === eco.name)
            .reduce((acc, item) => acc + item.units * item.price.price, 0) / mv,
        color: eco.color,
      }
    })
    .sort((a, b) => b.allocation - a.allocation)

  const treeMapData = {
    name: "details",
    children: holdings
      .filter(holding => {
        if (showCategories) {
          return holding.asset.categoryName === detailCategory
        } else {
          return holding.asset.ecosystemName === detailCategory
        }
      })
      .map(holding => {
        return {
          name: holding.asset.name,
          allocation: (holding.units * holding.price.price) / mv,
        }
      }),
  }

  const emptyTreeMapData = {
    name: "template",
    children: [
      {
        name: "1",
        allocation: 0.2,
      },
      {
        name: "2",
        allocation: 0.4,
      },
      {
        name: "3",
        allocation: 0.1,
      },
      {
        name: "4",
        allocation: 0.15,
      },
      {
        name: "5",
        allocation: 0.15,
      },
    ],
  }
  const data = showCategories ? cats : ecos

  const customColors = [
    "hsl(173, 80%, 40%)",
    "hsl(173, 80%, 35%)",
    "hsl(173, 80%, 30%)",
    "hsl(173, 80%, 25%)",
    "hsl(173, 80%, 20%)",
    "hsl(173, 80%, 15%)",
    "hsl(173, 80%, 10%)",
    "hsl(173, 80%, 5%)",
  ]
  return (
    <Layout>
      <SectionHeader headerText="Current allocation" />
      <div className="lg:grid lg:grid-cols-2 lg:gap-4">
        <div>
          <div className="h-8 flex justify-center items-center">
            <span className="relative z-0 inline-flex shadow-sm rounded-md">
              <button
                type="button"
                className={`relative inline-flex items-center px-4 py-2 rounded-l-md border-2 border-gray-800 ${
                  showCategories
                    ? "bg-gradient-to-br from-teal-500 to-cyan-200"
                    : "bg-gray-700"
                } text-sm font-normal text-gray-600 hover:bg-gray-200`}
                onClick={() => {
                  setDetailCategory(null)
                  setShowCategories(true)
                }}
              >
                Categories
              </button>
              <button
                type="button"
                className={`-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border-2 border-gray-800 ${
                  !showCategories
                    ? "bg-gradient-to-tl from-teal-500 to-cyan-200"
                    : "bg-gray-700"
                } text-sm font-normal text-gray-600 hover:bg-gray-200`}
                onClick={() => {
                  setDetailCategory(null)
                  setShowCategories(false)
                }}
              >
                Blockchains
              </button>
            </span>
          </div>
          <div className="h-96">
            <ResponsivePie
              data={data}
              id="name"
              value="allocation"
              valueFormat={">-.1%"}
              colors={customColors}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={2}
              margin={{ top: 40, right: 80, bottom: 20, left: 80 }}
              activeInnerRadiusOffset={3}
              activeOuterRadiusOffset={6}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#CCC"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLinkLabelsDiagonalLength={10}
              arcLinkLabelsStraightLength={10}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["brighter", 5]],
              }}
              onClick={node => setDetailCategory(node.id)}
            />
          </div>
        </div>
        <div>
          {detailCategory ? (
            <h3>{detailCategory}</h3>
          ) : (
            <div className="h-8"></div>
          )}
          <div className="relative h-96">
            <ResponsiveTreeMap
              data={detailCategory ? treeMapData : emptyTreeMapData}
              identity="name"
              value="allocation"
              valueFormat=">-.1%"
              margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
              label={node =>
                detailCategory ? `${node.id} ${node.formattedValue}` : ""
              }
              labelSkipSize={12}
              labelTextColor="#ccc"
              leavesOnly={true}
              innerPadding={1}
              nodeOpacity={detailCategory ? 0.7 : 0.05}
              borderColor={
                detailCategory
                  ? { from: "color", modifiers: [["darker", 4]] }
                  : "#111"
              }
              colors={detailCategory ? customColors : { scheme: "greys" }}
            />
            <div
              className={`${
                detailCategory ? "hidden" : null
              } absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 px-8 py-4 w-1/3 bg-teal-300 bg-opacity-40 rounded-md flex justify-center items-center flex-col`}
            >
              <p className="text-center">Select a category to show details</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-32">
        <PortfolioTable holdings={holdings} />
      </div>
    </Layout>
  )
}

export default Allocation
